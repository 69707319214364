/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import axios from "axios";
import config from "./../config";

export class Metric {
  static getDeviceMetrics(deviceIds, isInterface) {
    // return axios.get([config.basename, "api/v1/metric-types"].join("/")); // TODO: Remove this as it is a mock
    if (isInterface) {
      return axios.get(
        [config.basename, `api/v1/metrics/tree/interface?masterId=${deviceIds}`].join(
          "/"
        )
      );
    } else {

      var url = process.env.NODE_ENV == "development" ?
      "http://10.252.25.208:8080/castlemock/mock/rest/project/ns92vK/application/kp7M4S/tree" :
      [config.basename, `api/v1/metrics/tree?csIds=${deviceIds}`].join("/") ;
      return axios.get(url);

    }
  }

  static async getAllMetricDevices(
    deviceIds,
    metricTypes,
    hours,
    selectedCIs,
    isInterface,
    endDate,
    aggregationIntervalValue,
    aggregateFunction,
    isInterpolation
  ) {     
  if(process.env.NODE_ENV == "development"){
      return axios.get('http://10.252.25.208:8080/castlemock/mock/rest/project/ns92vK/application/Qw2Qzs/charts');
    }
  else{
    if (isInterface) {
      return await Promise.all(
        metricTypes.map((metric) => {
          let urlString = `api/v1/metrics/chart?metricTypeId=${encodeURI(
            metric
          )}&csIds=${encodeURI(deviceIds)}&minutes=${encodeURI(
            Math.floor(hours * 60)
          )}&timezone=${encodeURI(
            Intl.DateTimeFormat().resolvedOptions().timeZone
          )}&endDateEpoch=${new Date(endDate).setSeconds(0,0)}&aggregationInterval=${aggregationIntervalValue}&aggregateFunction=${aggregateFunction}&interpolateData=${isInterpolation?isInterpolation:false}`; //dp039841
          if (
            selectedCIs.hasOwnProperty(metric) &&
            selectedCIs[metric].length
          ) {
            urlString += `&ciNames=${encodeURI(selectedCIs[metric].join())}`;
          }
          return axios.get([config.basename, urlString].join("/"));
        })
      );
    } else {
      return await Promise.all(
        metricTypes.map((metric) => {
          let urlString = `api/v1/metrics/chart?metricTypeId=${encodeURI(
            metric
          )}&csIds=${encodeURI(deviceIds)}&minutes=${encodeURI(
           Math.floor(hours * 60)
          )}&timezone=${encodeURI(
            Intl.DateTimeFormat().resolvedOptions().timeZone
          )}&endDateEpoch=${new Date(endDate).setSeconds(0,0)}&aggregationInterval=${aggregationIntervalValue}&aggregateFunction=${aggregateFunction}&interpolateData=${isInterpolation?isInterpolation:false}`; //dp039841

          if (
            selectedCIs.hasOwnProperty(metric) &&
            selectedCIs[metric].length
          ) {
            urlString += `&ciNames=${encodeURI(selectedCIs[metric].join())}`;
          }
          return axios.get([config.basename, urlString].join("/"));
        })
      );
    }
   }
  }

  static saveNamedMetrics(data, isUpdateView) {
    // return axios.get([config.basename, "api/v1/metrics/named-views"].join("/")); // TODO: Remove this as it is a mock
    if (isUpdateView) {
      return axios.put(
        [config.basename, "api/v1/metrics/named-views"].join("/"),
        data
      );
    } else {
      return axios.post(
        [config.basename, "api/v1/metrics/named-views"].join("/"),
        data
      );
    }
  }

  static getAllNamedMetrics(body) {    
    if (body.hasOwnProperty("masterId")) {
      var url = process.env.NODE_ENV == "development" ?
       'http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/named-views/csId' :
       [config.basename, `api/v1/metrics/named-views?masterId=${body.masterId}&csId=${body.csId}`].join( "/");

      return axios.get(url);
    } else if (body.hasOwnProperty("csId")) {
      var url = process.env.NODE_ENV == "development" ?
       'http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/named-views/csId' :
       [config.basename, `api/v1/metrics/named-views?csId=${body.csId}`].join( "/");

      return axios.get(url);
    } else {
      var url = process.env.NODE_ENV == "development" ?
      "http://10.252.25.208:8080/castlemock/mock/rest/project/ns92vK/application/gwx4M1/named-views" :
      [config.basename, `api/v1/metrics/named-views?groupId=${body.groupId}`].join("/");
      
      return axios.get(url);  
      
    }
  }

  static getNamedMetricsById(data) {
    // return axios.get(
    //   [config.basename, `api/v1/metrics/named-views-by-id?id=${data.id}`].join(
    //     "/"
    //   )
    // ); // TODO: Remove this as it is a mock
    if(process.env.NODE_ENV==="development")
    {
      return axios.get(`http://10.252.25.208:8080/castlemock/mock/rest/project/TK8t3o/application/UXxhjW/named-views-akanksha
      `);
    }
    return axios.get(
      [config.basename, "api/v1/metrics/named-views", data.id].join("/")
    );
  }

  static deleteNamedViews(id) {
    // return axios.get(
    //   [config.basename, "api/v1/metrics/delete-views"].join("/")
    // ); // TODO: Remove this as it is a mock
    return axios.delete(
      [config.basename, "api/v1/metrics/named-views", id].join("/")
    );
  }

  static getDevices(params) {
    const url = process.env.NODE_ENV == "development" ?
      "http://10.252.25.208:8080/castlemock/mock/rest/project/ns92vK/application/VZh4LS/search" :
    [config.basename, `api/v1/metrics/named-views/search`].join("/");

    return axios.post(url,params);
  }

  static copyMetricsData(id, name) {
    // return axios.get(
    //   [config.basename, "api/v1/metrics/copy-named-views"].join("/")
    // ); // TODO: Remove this as it is a mock
    return axios.post(
      [
        config.basename,
        `api/v1/metrics/named-views/copy?sourceMetricViewId=${id}&metricViewNameToCopy=${name}`,
      ].join("/")
    );
  }

  static async getExportData(
    deviceIds,
    metricTypes,
    hours,
    selectedCIs,
    isInterface,
    endDate,
    aggregationIntervalValue,
    aggregateFunction
  ) {
    if (isInterface) {
      return await Promise.all(
        metricTypes.map((metric) => {
          let urlString = `api/v1/metricesExportData/interfaceMetricesExportData?aggregateFunction=${aggregateFunction}&aggregationInterval=${aggregationIntervalValue}&metricTypeId=${encodeURI(
            metric
          )}&masterIds=${encodeURI(deviceIds)}&minutes=${encodeURI(
           Math.floor(hours * 60)
          )}&timezone=${encodeURI(
            Intl.DateTimeFormat().resolvedOptions().timeZone
          )}&endDateEpoch=${new Date(endDate).setSeconds(0,0)}&aggregationInterval=${aggregationIntervalValue}&aggregateFunction=${aggregateFunction}`;
          if (
            selectedCIs.hasOwnProperty(metric) &&
            selectedCIs[metric].length
          ) {
            urlString += `&ciNames=${encodeURI(selectedCIs[metric].join())}`;
          }
          return axios.get([config.basename, urlString].join("/"));
        })
      );
    } else {
      return await Promise.all(
        metricTypes.map((metric) => {
          let urlString = `api/v1/metricesExportData/deviceMetricesExportData?aggregateFunction=${aggregateFunction}&aggregationInterval=${aggregationIntervalValue}
          &metricTypeId=${encodeURI(
            metric
          )}&csIds=${encodeURI(deviceIds)}&minutes=${encodeURI(
           Math.floor(hours * 60)
          )}&timezone=${encodeURI(
            Intl.DateTimeFormat().resolvedOptions().timeZone
          )}&endDateEpoch=${new Date(endDate).setSeconds(0,0)}`;
          if (
            selectedCIs.hasOwnProperty(metric) &&
            selectedCIs[metric].length
          ) {
            urlString += `&ciNames=${encodeURI(selectedCIs[metric].join())}`;
          }
          return axios.get([config.basename, urlString].join("/"));
        })
      );
    }
  }

  static generatePdf(viewName,reqBody, sid, isInterface,paperASize) {
    let metricViewName = viewName;
    let url = isInterface
      ? `${window.location.protocol}//${window.location.host}/reportscheduler/api/v1/report/metricViewInterfaceReport`
      : `${window.location.protocol}//${window.location.host}/reportscheduler/api/v1/report/metricViewDeviceReport`;

    return axios({
      url,
      method: "post",
      data:reqBody,
      params: {metricViewName,paperASize},
      headers: { ocAuthorizationToken: sid },
      responseType: "blob",
    });
  }

  //dp039841
  static getFilteredDevices(requestBody, targetType){
    //console.log("inside api")
    if(process.env.NODE_ENV == "development"){
     let url= targetType == "device" ? 
     "http://10.252.25.208:8080/castlemock/mock/rest/project/ns92vK/application/CugkXh/filteredDevices" :
     "http://10.252.25.208:8080/castlemock/mock/rest/project/ns92vK/application/edjXGH/filteredGroup" ;
          
      return axios.get(url);
    }else{
      let url = targetType == "device" ? 
            [config.basename, "api/v1/metrics/named-views/searchComputerSystems"].join("/") :
            [config.basename, "api/v1/metrics/named-views/searchGroups"].join("/")
      return axios.post(url, requestBody); 
    }
   
  }

  static saveDeviceMetricView(requestbody, targetType){
    let url = targetType == "device" ?
    [config.basename, "api/v1/metrics/named-views/copyDeviceLevelMetricView"].join("/"):
    [config.basename, "api/v1/metrics/named-views/copyGroupLevelMetricView"].join("/")
    return axios.post(url, requestbody);    
  }
}

// let MetricAPI = new Metric();
// export default MetricAPI;
