/*
 * Copyright © 2023 Broadcom. All rights reserved. The term “Broadcom” refers to Broadcom Inc. and/or its subsidiaries. All trademarks, trade names, service marks, and logos referenced herein belong to their respective companies.
 * This software and all information contained therein is confidential and proprietary and shall not be duplicated, used, disclosed or disseminated in any way except as authorized by the applicable license agreement, without the express written permission of Broadcom. All authorized reproductions must be marked with this language.
 * EXCEPT AS SET FORTH IN THE APPLICABLE LICENSE AGREEMENT, TO THE EXTENT PERMITTED BY APPLICABLE LAW OR AS AGREED BY BROADCOM IN ITS APPLICABLE LICENSE AGREEMENT, BROADCOM PROVIDES THIS DOCUMENTATION “AS IS” WITHOUT WARRANTY OF ANY KIND, INCLUDING WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. IN NO EVENT WILL BROADCOM BE LIABLE TO THE END USER OR ANY THIRD PARTY FOR ANY LOSS OR DAMAGE, DIRECT OR INDIRECT, FROM THE USE OF THIS DOCUMENTATION, INCLUDING WITHOUT LIMITATION, LOST PROFITS, LOST INVESTMENT, BUSINESS INTERRUPTION, GOODWILL, OR LOST DATA, EVEN IF BROADCOM IS EXPRESSLY ADVISED IN ADVANCE OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE.
 */
import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { SearchIcon, ClearIcon as Clear } from "../../../ui-components/uim-components";
import {
  Button,
  Table,
  TableBody, TableHead,
  TableCell,
  TableRow,
  TableContainer,
  Tooltip,
  OutlinedInput,
  Checkbox,
  Select as SelectField,
  IconButton,
  TextField, Grid, FormControl, InputLabel,
  MenuItem, Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@mineral/core";
import "./deviceList.less";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { isEqual, uniq } from "../../../../utils/lodash-utils";

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    isMaximizedView,
    isAllSelected,
  } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          style={{ width: "10px", paddingLeft: "18px" }}
        >
          <Checkbox
            indeterminate={
              numSelected > 0 && numSelected < rowCount && !isAllSelected
            }
            inputProps={{ "aria-label": "Select all" }}
            checked={rowCount > 0 && isAllSelected}
            onChange={onSelectAllClick}
          />
        </TableCell>
        <TableCell align="left" padding="none">
          Name
        </TableCell>
        <TableCell align="left" padding="none">
          Type
        </TableCell>
        {isMaximizedView && (
          <TableCell align="left" padding="none">
            Operating System
          </TableCell>
        )}
        {isMaximizedView && (
          <TableCell align="left" padding="none">
            IP Address
          </TableCell>
        )}
        {isMaximizedView && (
          <TableCell align="left" padding="none">
            Monitored By
          </TableCell>
        )}
        {isMaximizedView && (
          <TableCell align="left" padding="none">
            Discovered
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
}

class DeviceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: {
        fixedHeader: true,
        fixedFooter: true,
        stripedRows: false,
        showRowHover: false,
        selectable: true,
        multiSelectable: true,
        enableSelectAll: true,
        deselectOnClickaway: false,
        showCheckboxes: true,
        height: "calc(100vh - 355px)",
        searchString: "",
        advanceFilterApplied: false,
      },
      isMaximizedView: false,
      selectedIndices: this.props.selectedIndices,
      selectedDeviceIds: this.props.selectedCsIds,
      selectionExceeded: false,
      advanceFilter: [
        {
          field: "name",
          operator: "=",
          value: "",
        },
      ],
      advanceFilterCondition: "All",
      advanceCustomFilter: "",
      advanceFilterOpen: false,
      pageNo: 0,
      computerSystemList: [],
      anchorEl: null,
      selectedDeviceIds: [],
      showCloseAdvanceFilterConfirmation:false,
      showCloseMetricsCreateConfirmation:false
    };
  }

  isSelected = (cs_id,index) => {
    /*  if(this.state.selectedIndices[0] == "all"){
        let selectDevicesArr= this.state.selectedIndices;
          selectDevicesArr.push(index);
        this.setState({selectedIndices: selectDevicesArr});
      return true;
     }else{ */
    //return this.state.selectedIndices.indexOf(index) !== -1;
    return this.state.selectedDeviceIds.length > 0 ? this.state.selectedDeviceIds.includes(cs_id):this.state.selectedIndices.indexOf(index) !== -1;

    //}
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.tableData, this.props.tableData)) {
      let computerSystemList = this.state.computerSystemList;
      if (this.state.pageNo === 0) {
        computerSystemList = this.props.tableData;
      } else {
        if (this.props.tableData && this.props.tableData.length > 0) {
          computerSystemList = computerSystemList.concat(this.props.tableData);
        }
      }
      let selectDevicesArr = [];
      let selectDevicesIdArr = [];
      if (this.props.viewAction == 2) {
        let loadedDevice = this.props.deviceMetrics.deviceIds.length
          ? this.props.deviceMetrics.deviceIds.split(",")
          : [];
        loadedDevice.length &&
          this.props.tableData.map(
            (dev, i) =>
              loadedDevice.includes(dev.id.toString()) &&
              selectDevicesArr.push(i) &&
              selectDevicesIdArr.push(dev.id)
          );
      }
      if (this.props.isBackClicked && !this.state.advanceFilterOpen) {
        selectDevicesArr = this.props.selectedIndices;
        selectDevicesIdArr = this.props.selectedCsIds;
        let advanceFilterTextGrp =this.props.advanceFilterTextGrp;
        if(advanceFilterTextGrp){
          this.setState({
          advanceFilter:advanceFilterTextGrp.advanceFilter,
          advanceFilterCondition: advanceFilterTextGrp.advanceFilterCondition,
          advanceCustomFilter:advanceFilterTextGrp.advanceCustomFilter
        });
        }
      }

      this.state.selectedDeviceIds.length > 0 &&
        this.props.tableData.map(
          (dev, i) =>
            this.state.selectedDeviceIds.includes(dev.id) &&
            selectDevicesArr.push(i) && selectDevicesIdArr.push(dev.id)
        );
      selectDevicesArr = uniq(selectDevicesArr);
      selectDevicesIdArr = uniq(selectDevicesIdArr)
      this.setState({
        computerSystemList: computerSystemList,
        selectedIndices: selectDevicesArr,
        selectedDeviceIds: selectDevicesIdArr,
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.drawerIsClosing) {
      this.setState({ selectedIndices: [], selectedDeviceIds: [] });
    } else if (
      nextProps.viewAction != this.props.viewAction &&
      nextProps.viewAction == 1
    ) {
      this.setState({ selectedIndices: [], selectedDeviceIds: [] });
    } else if (
      nextProps.viewAction == this.props.viewAction &&
      nextProps.viewAction == 1
    ) {
      if (sessionStorage.getItem("newCalled")) {
        this.setState({ selectedIndices: [], selectedDeviceIds: [] });
        sessionStorage.removeItem("newCalled");
      }
    } else if (
      nextProps.viewAction != this.props.viewAction &&
      nextProps.viewAction == 2
    ) {
      let selectDevicesArr = [];
      let selectDevicesIdArr = [];
      let loadedDevice = nextProps.deviceMetrics.deviceIds.length
        ? nextProps.deviceMetrics.deviceIds.split(",")
        : [];
      loadedDevice.length &&
        this.props.tableData.map(
          (dev, i) =>
            loadedDevice.includes(dev.id.toString()) && selectDevicesArr.push(i) && selectDevicesIdArr.push(dev.id)
        );
      this.setState({ selectedIndices: selectDevicesArr, selectedDeviceIds: selectDevicesIdArr });
    } else if (
      nextProps.viewAction == this.props.viewAction &&
      nextProps.viewAction == 2
    ) {
      if (sessionStorage.getItem("editForDvcCalled")) {
        let selectDevicesArr = [];
        let selectDevicesIdArr = [];
        let loadedDevice = nextProps.deviceMetrics.deviceIds.length
          ? nextProps.deviceMetrics.deviceIds.split(",")
          : [];
        loadedDevice.length &&
          this.props.tableData.map(
            (dev, i) =>
              loadedDevice.includes(dev.id.toString()) &&
              selectDevicesArr.push(i) && selectDevicesIdArr.push(dev.id)
          );
        this.setState({ selectedIndices: selectDevicesArr, selectedDeviceIds: selectDevicesIdArr });
        sessionStorage.removeItem("editForDvcCalled");
      }
    }
  }

  handleCancel = () => {
    this.setState({
      selectedIndices: [],
      selectedDeviceIds: [],
      searchString: "",
      advanceFilterApplied: false,
      pageNo: 0,
    });
    this.advanceFilterDisable();
    this.props.toggleDrawer();
    this.props.loadedView();
  };
  nextPage = () => {
    let pageNo = this.state.pageNo + 1;
    let isAdvanceFilter = this.state.advanceFilterApplied;
    this.setState({ pageNo: pageNo });
    const { groupId, filterDeviceList } = this.props;
    const advanceFilter = isAdvanceFilter ? this.state.advanceFilter : [];
    const advanceFilterCondition = this.state.advanceFilterCondition;
    const advanceCustomFilter = this.state.advanceCustomFilter;
    const searchString = isAdvanceFilter ? "" : this.state.searchString;
    const advanceFilterText = {
      groupId: groupId,
      searchText: searchString,
      advanceFilter: advanceFilter,
      advanceFilterCondition: advanceFilterCondition,
      advanceCustomFilter: advanceCustomFilter,
      pageNo: pageNo,
    };
    filterDeviceList(advanceFilterText);
  };
  handleChange = (e) => {
    let value = e.target.value;
    this.setState({ searchString: value, pageNo: 0 });
    const { groupId, filterDeviceListStore } = this.props;
    const advanceFilterText = {
      groupId: groupId,
      searchText: value,
      advanceFilter: [],
      advanceFilterCondition: "All",
      advanceCustomFilter: "",
      pageNo: 0,
    };
    filterDeviceListStore(advanceFilterText, false);
  };

  handleAdvanceSearch = () => {
    const advanceFilter = this.state.advanceFilter;
    const advanceFilterCondition = this.state.advanceFilterCondition;
    const advanceCustomFilter = this.state.advanceCustomFilter;
    const { groupId, filterDeviceListStore } = this.props;
    const advanceFilterText = {
      groupId: groupId,
      searchText: "",
      advanceFilter: advanceFilter,
      advanceFilterCondition: advanceFilterCondition,
      advanceCustomFilter: advanceCustomFilter,
      pageNo: 0,
    };
    this.setState({
      advanceFilterOpen: false,
      anchorEl: null,
      advanceFilterApplied: true,
      pageNo: 0,
    });
    filterDeviceListStore(advanceFilterText, true);
  };
  advanceFilterEnable = (event) => {
    event.preventDefault();
    this.setState({ advanceFilterOpen: true, anchorEl: event.currentTarget });
  };
  advanceFilterDisable = () => {
    this.setState({
      advanceFilterOpen: false,
      anchorEl: null,
      advanceFilterApplied: false,
      advanceFilter: [
        {
          field: "name",
          operator: "=",
          value: "",
        },
      ],
      advanceFilterCondition: "All",
      pageNo: 0,
      searchString: "",
    });
    const { groupId, filterDeviceListStore } = this.props;
    const advanceFilterText = {
      groupId: groupId,
      searchText: "",
      advanceFilter: [],
      advanceFilterCondition: "All",
      advanceCustomFilter: "",
      pageNo: 0,
    };
    filterDeviceListStore(advanceFilterText,true);
  };

  handleFilterCondition = (event) => {
    let payload = event.target.value;
    let customFilterText =
      payload !== "Custom" ? "" : this.state.advanceCustomFilter;
    this.setState({
      advanceFilterCondition: payload,
      advanceCustomFilter: customFilterText,
    });
  };
  handleCustomFilter = (eventValue) => {
    this.setState({
      advanceCustomFilter: eventValue,
    });
  };
  handleAddFilter = (index) => {
    let advanceFilter = this.state.advanceFilter;
    advanceFilter.push({
      field: "name",
      operator: "=",
      value: "",
    });
    this.setState({
      advanceFilter: advanceFilter,
    });
  };
  handleRemoveFilter = (index) => {
    let advanceFilter = this.state.advanceFilter;
    advanceFilter.splice(index, 1);
    this.setState({
      advanceFilter: advanceFilter,
    });
  };
  handleFieldChange = (event, index) => {
    let value = event.target.value;
    let advanceFilter = this.state.advanceFilter;
    let advanceFilterObj = this.state.advanceFilter[index];
    advanceFilterObj.field = value;
    advanceFilter[index] = advanceFilterObj;
    this.setState({
      advanceFilter: advanceFilter,
    });
  };
  handleOperatorChange = (event, index) => {
    let value = event.target.value;
    let advanceFilter = this.state.advanceFilter;
    let advanceFilterObj = this.state.advanceFilter[index];
    advanceFilterObj.operator = value;
    advanceFilter[index] = advanceFilterObj;
    this.setState({
      advanceFilter: advanceFilter,
    });
  };
  handleValueChange = (index, eventValue) => {
    let value = eventValue;
    let advanceFilter = this.state.advanceFilter;
    let advanceFilterObj = this.state.advanceFilter[index];
    advanceFilterObj.value = value;
    advanceFilter[index] = advanceFilterObj;
    this.setState({
      advanceFilter: advanceFilter,
    });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let selectedIndices = [];
      let selectedDeviceIds = [];
      selectedIndices.push("all");
      this.props.tableData.map((device, index) => {
        selectedIndices.push(index);
        selectedDeviceIds.push(device.id);
      });

      this.setState({
        selectedIndices: selectedIndices,
        selectionExceeded: this.props.tableData.length > 100,
        selectedDeviceIds: selectedDeviceIds,
      });
    } else {
      this.setState({ selectedIndices: [], selectedDeviceIds: [] });
    }
  };

  handleClick = (csId, event, index) => {
    let selectedIndices = this.state.selectedIndices;
    let selectdCsIds = this.state.selectedDeviceIds;
    //selectdCsIds.push(csId);
    selectedIndices = selectedIndices.filter((e) => e !== "all");
    if (event.target.checked) {
      selectedIndices.push(index);
      selectdCsIds.push(csId);
    } else {
      selectedIndices = selectedIndices.filter((e) => e !== index);
      selectdCsIds = selectdCsIds.filter((e) => e !== csId);
    }
    this.setState({
      selectedDeviceIds: selectdCsIds,
      selectedIndices: selectedIndices,
      selectionExceeded: selectedIndices.length > 100,
    });
  };

  advanceSearchUI = () => {
    let confirmMassage = 'Are you sure you want to close the dialog?'
    const confirmActions = [

      <Button

        children={"No"}
        variant="outlined"
        color="primary"
        onClick={()=>{this.setState({
          showCloseAdvanceFilterConfirmation:false
        });}}

      />
     ,

      <Button

        children={"Yes"}
        variant="contained"
        color="error"
        onClick={() => {
          this.setState({
          showCloseAdvanceFilterConfirmation:false
        });
          this.advanceFilterDisable()}}

      />  ];

    return (
      <>
      <Dialog
       open={this.state.showCloseAdvanceFilterConfirmation}
       onClose={()=>{this.setState({
          showCloseAdvanceFilterConfirmation:false
        });}}
     >
       <DialogTitle component="div" disableTypography>


             <Typography component="h1" variant="h4">
               {"Confirm exit"}
             </Typography>

       </DialogTitle>
       <div style={{height:'0px'}}>

       <Tooltip title={"Close"} style={{float:'right',marginRight:'8px',top:'-50px'}}>
        <IconButton autoFocus  onClick={()=>{this.setState({
          showCloseAdvanceFilterConfirmation:false
        });}}>
          <Clear role='figure' />
        </IconButton>
         </Tooltip>
       </div>
       <DialogContent>{confirmMassage}</DialogContent>
       <DialogActions>
         {confirmActions}
       </DialogActions>
     </Dialog>


      <Dialog

        open={this.state.advanceFilterOpen}
        maxWidth={"lg"}
        onClose={()=>{this.advanceFilterDisable()}}//() => this.advanceFilterDisable()}
      >
        <DialogTitle component="div">
         <Typography component="h1" variant="h3">
           Match Filters
            </Typography>

        </DialogTitle>
        <div style={{height:'0px'}}>
        <Tooltip title="Close">
        <IconButton aria-label="Close" style={{top:'-50px',marginRight:'8px',float:'right'}}
              onClick={()=>{this.advanceFilterDisable()}}
              autoFocus={true}
            >
              <CloseIcon />
            </IconButton>
            </Tooltip>
          </div>
        <DialogContent style={{  overflow: "auto" }}>
        <Grid container style={{marginTop:'8px',marginBottom:'8px'}}>
          <Grid item xs="4">
          <FormControl fullWidth>
          <InputLabel id="filterTypeLabelId" htmlFor="filterType">Filter type</InputLabel>
            <SelectField fullWidth id="filterType" labelId="filterTypeLabelId"
             inputProps={{  role: 'combobox', }}
                      MenuProps={{
       MenuListProps:{

         'aria-label' :"Filter type options list",

        }}}
              onChange={this.handleFilterCondition}
              value={this.state.advanceFilterCondition}
            >
              <MenuItem value={"Any"} children={"Any"} />
              <MenuItem value={"All"} children={"All"} />
              <MenuItem value={"Custom"} children={"Custom"} />
            </SelectField>
          </FormControl>
          </Grid>
        </Grid>

          {this.state.advanceFilterCondition === "Custom" ? (
            <Grid container  style={{marginBottom:'16px',marginTop:'8px'}}>
          <Grid item xs="4">
              <FormControl fullWidth>
                <InputLabel id="customFilterId" htmlFor="customFilter">{"Custom Filter"}</InputLabel>

                <TextField fullWidth id="customFilter" labelId="customFilterId"
                  placeholder="e.g.((1 AND 2) OR 3)"
                  sx={{
      input: {

         "&::placeholder": {    // <----- Add this.
            color: 'black !important',
            opacity:0.8

         },
      }}}
      inputProps={{ "aria-label": "Custom Filter" }}

                  value={this.state.advanceCustomFilter}
                  onChange={(event) =>
                    this.handleCustomFilter(event.target.value)
                  }
                  variant="outlined"
                  color="secondary"
                />
                  </FormControl>
                  </Grid></Grid>

          ) : null}

          <Table

          /*  fixedHeader={true}
          fixedFooter={false}
          selectable={false}
          multiSelectable={false} */
          >
            <TableHead
              displaySelectAll={false}
              adjustForCheckbox={false}
              enableSelectAll={false}
            >
              <TableRow>
                <TableCell

                  id="advancefilter-id"
                >
                  ID
                </TableCell>
                <TableCell

                  id="advancefilter-field"
                >
                  Field
                </TableCell>
                <TableCell

                  id="advancefilter-operator"
                >
                  Operator
                </TableCell>
                <TableCell

                  id="advancefilter-value"
                >
                  Value
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody
              displayRowCheckbox={false}
              deselectOnClickaway={false}
              showRowHover={false}
              stripedRows={false}
            >
              {this.state.advanceFilter.map((row, index) => (
                <TableRow key={index} style={{ height: "28px" }}>
                  <TableCell
                    style={{

                      paddingTop: "2px !important",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        margin: "auto",
                        width: "100%",
                      }}
                    >
                      <SelectField
                        className="advancefilter-selectfield"
                        onChange={(event, key, value) =>
                          this.handleFieldChange(event, index)
                        }
                        id={"field"+index+1}
                        MenuProps={{
                          MenuListProps:{'aria-label' :"Field options list at index"+(index+1),}
                        }}
                        inputProps={{
                          "aria-labelledby": "advancefilter-field", role: 'combobox',
                        }}
                        value={row.field}
                      >
                        <MenuItem
                          value={"name"}
                          label={"Name"}
                          children={"Name"}
                        />
                        <MenuItem
                          value={"alias"}
                          label={"Alias"}
                          children={"Alias"}
                        />
                        <MenuItem
                          value={"ip_address"}
                          label={"IP Address"}
                          children={"IP Address"}
                        />
                        <MenuItem
                          value={"os_name"}
                          label={"OS Name"}
                          children={"OS Name"}
                        />
                        <MenuItem
                          value={"os_type"}
                          label={"OS Type"}
                          children={"OS Type"}
                        />
                        <MenuItem
                          value={"origin"}
                          label={"Origin"}
                          children={"Origin"}
                        />
                        <MenuItem
                          value={"mac_address"}
                          label={"MAC Address"}
                          children={"MAC Address"}
                        />
                        <MenuItem
                          value={"role"}
                          label={"Role"}
                          children={"Role"}
                        />
                      </SelectField>
                    </div>
                  </TableCell>
                  <TableCell >
                    <div
                      style={{ display: "flex", margin: "auto", width: "100%" }}
                    >
                      <SelectField
                        className="advancefilter-selectfield"
                        onChange={(event, key, value) =>
                          this.handleOperatorChange(event, index)
                        }
                        value={row.operator}
                        inputProps={{
                          "aria-labelledby": "advancefilter-operator",role: 'combobox'
                        }}
                        MenuProps={{
                          MenuListProps:{'aria-label' :"Operator options list at index"+(index+1),}
                        }}

                      >
                        <MenuItem value={"="} label={"="} children={"="} />
                        <MenuItem value={"!="} label={"!="} children={"!="} />
                        <MenuItem
                          value={"contains"}
                          label={"contains"}
                          children={"contains"}
                        />
                        <MenuItem
                          value={"!contains"}
                          label={"!contains"}
                          children={"!contains"}
                        />
                      </SelectField>
                    </div>
                  </TableCell>
                  <TableCell >
                    <OutlinedInput size="small"
                      placeholder=""
                      value={row.value}
                      inputProps={{
                        "aria-labelledby": "advancefilter-value",
                      }}
                      onChange={(event) =>
                        this.handleValueChange(index, event.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell >
                    <div
                      style={{ display: "flex", margin: "auto", width: "100%" }}
                    >
                      {this.state.advanceFilter.length - 1 === index ? (
                        <Tooltip title="Add">
                        <IconButton
                          aria-label={"Add at row"+(index+1)}
                          onClick={() => this.handleAddFilter(index)}
                        >
                          <AddIcon />
                        </IconButton>
                        </Tooltip>
                      ) : null}
                      {this.state.advanceFilter.length !== 1 &&
                      this.state.advanceFilter.length - 1 >= index ? (
                        <Tooltip title="Remove">
                        <IconButton
                           aria-label={"Remove row"+(index+1)}
                          onClick={() => this.handleRemoveFilter(index)}
                        >
                          <RemoveIcon />
                        </IconButton>
                        </Tooltip>
                      ) : null}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>

          <Button variant="text" onClick={()=>{this.advanceFilterDisable()}}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => this.handleAdvanceSearch()}>
            Apply
          </Button>
        </DialogActions>
      </Dialog>
      </>
    );
  };

  render() {
    let computerSystemList = this.state.computerSystemList;

    const deviceCount =
      this.state.selectedIndices[0] === "all"
        ? computerSystemList.length
        : this.state.selectedIndices.length;
        const confirmActions = [

          <Button

            children={"No"}
            variant="outlined"
            color="primary"
            onClick={()=>{this.setState({
              showCloseMetricsCreateConfirmation:false
            });}}

          />
         ,

          <Button

            children={"Yes"}
            variant="contained"
            color="error"
            onClick={()=>{
              this.setState({
          showCloseMetricsCreateConfirmation:false
        });
        this.handleCancel()}}

          />  ];
            let confirmMassage = 'Are you sure you want to close the dialog?'
    return (
      <>
       <Dialog
       open={this.state.showCloseMetricsCreateConfirmation}
       onClose={()=>{this.setState({
          showCloseMetricsCreateConfirmation:false
        });}}
     >
       <DialogTitle component="div" disableTypography>


             <Typography component="h1" variant="h4">
               {"Confirm exit"}
             </Typography>

       </DialogTitle>
       <div style={{height:'0px'}}>

       <Tooltip title={"Close"} style={{float:'right',marginRight:'8px',top:'-50px'}}>
        <IconButton autoFocus  onClick={()=>{this.setState({
          showCloseMetricsCreateConfirmation:false
        });}}>
          <Clear role='figure' />
        </IconButton>
         </Tooltip>
       </div>
       <DialogContent>{confirmMassage}</DialogContent>
       <DialogActions>
         {confirmActions}
       </DialogActions>
       </Dialog>
        <div className="device-view">
          <span
            style={{
              color: "#666",
              margin: "0 16px 16px 0px",
            }}
          >
            Select Devices
          </span>
          <span
            style={{
              color: "#747474",
            }}
          >
            {deviceCount} Selected
          </span>
          <span
            className="stepper"
            style={{

              float: "right",
            }}
          >
            {" "}
            Step 1 of 2
          </span>
          <div style={{marginTop:'16px'}}>
            <OutlinedInput fullWidth  sx={{
      input: {

         "&::placeholder": {    // <----- Add this.
            color: 'black !important',
            opacity:0.8

         }}}}
              placeholder="Search by name"
              inputProps={{ "aria-label": "Search by name" }}
              value={this.state.searchString}
              onChange={this.handleChange}
              // InputProps={{ disableUnderline: true }}
              style={{ paddingRight: "0" }}
              endAdornment={
                <SearchIcon role="figure" title="Search" name="Search" />
              }
            />
            {/* <span className="search-icon">
            <SearchIcon />
          </span> */}
          </div>
          <div style={{marginTop:'16px'}}>
            <Button style={{float:'left'}}

              variant="text"
              onClick={this.advanceFilterEnable}
            >
              Advanced Filter
            </Button>
            <Button style={{float:'right'}}

              variant="text"
              onClick={this.advanceFilterDisable}
            >
              Clear Filter
            </Button>
          </div>
          <Grid container style={{paddingTop:'8px'}}>
          <TableContainer style={{ height: "62vh" }}>
            <Table aria-label="List of devices"
              //height={this.state.table.height}
              wrapperStyle={{ width: "100%" }}
              fixedHeader={this.state.table.fixedHeader}
              fixedFooter={this.state.table.fixedFooter}
              selectable={this.state.table.selectable}
              multiSelectable={this.state.table.multiSelectable}
              onRowSelection={this.onSelectRow}

            >
              <EnhancedTableHead
                onSelectAllClick={this.handleSelectAllClick}
                numSelected={this.state.selectedIndices.length}
                rowCount={this.state.computerSystemList.length}
                isMaximizedView={this.state.isMaximizedView}
                isAllSelected={
                  this.state.selectedIndices[0] == "all" ? true : false
                }
              />

              <TableBody>
                {this.state.computerSystemList.map((row, index) => {
                  const isItemSelected = this.isSelected(row.id,index);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      onClick={(event) =>
                        this.handleClick(row.id, event, index)
                      }
                      tabIndex={-1}
                      key={row.id}
                      //selected={isItemSelected}

                    >
                      <TableCell
                        padding="checkbox"
                        style={{ paddingTop: "14px", paddingLeft: "18px" }}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) => {
                            this.handleClick(row.id, event, index);
                            event.stopPropagation();
                          }}
                          inputProps={{ "aria-label": `Select row with name ${row.alias} and type ${row.role}` }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        id={labelId}
                        scope="row"
                        padding="none"
                        style={{ paddingTop: "14px" }}
                      >
                        {row.alias}
                      </TableCell>
                      <TableCell
                        align="left"
                        padding="none"
                        style={{ paddingTop: "14px" }}
                      >
                        {row.role}
                      </TableCell>
                      {this.state.isMaximizedView && (
                        <TableCell
                          align="left"
                          padding="none"
                          style={{ paddingTop: "14px" }}
                        >
                          {row.osType}
                        </TableCell>
                      )}
                      {this.state.isMaximizedView && (
                        <TableCell
                          align="left"
                          padding="none"
                          style={{ paddingTop: "14px" }}
                        >
                          {row.ip_address}
                        </TableCell>
                      )}
                      {this.state.isMaximizedView && (
                        <TableCell
                          align="left"
                          padding="none"
                          style={{ paddingTop: "14px" }}
                        >
                          {row.monitored_by}
                        </TableCell>
                      )}
                      {this.state.isMaximizedView && (
                        <TableCell
                          align="left"
                          padding="none"
                          style={{ paddingTop: "14px" }}
                        >
                          {row.discovered}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {this.state.computerSystemList.length === 0 ? (
              <div style={{ padding: "5px", textAlign: "center" }}>
                No results
              </div>
            ) : null}
          </TableContainer>
          </Grid>
          {this.state.selectionExceeded && (
            <div
              style={{
                margin: "10px 0 0 16px",
                padding: "5px",
                color: "#333",
              }}
            >
              Maximum 100 devices can be selected
            </div>
          )}
          <div
            style={{
              margin: "10px 0 0 16px",
            }}
          >
            <div

              style={{ float:'right',marginTop:'auto'}}
            >
              <Button
                // style={{
                //   float: "right",
                //   //paddingRight: "20px",
                //   paddingTop: "10px",
                //   color: "#999",
                //   cursor: "pointer",
                // }}
                variant="text"
                onClick={()=>{this.handleCancel()}}
                // className="cancel--btn"
                style={{ marginRight: "16px" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                children="Next"
                // style={{
                //   float: "right",
                //   paddingRight: "20px",
                //   backgroundColor: "#1976d2",
                //   color: "#ffffff !important",
                //   borderRadius: "0px !important",
                // }}
                disabled={
                  !this.state.selectedIndices.length ||
                  this.state.selectionExceeded ||
                  this.props.viewName.trim().length == 0
                }
                onClick={() =>
                  this.props.openMetric(this.state.selectedIndices, this.state.selectedDeviceIds)
                }
                style={{ marginRight: "16px" }}
              />
             {/*<Button
                variant="contained"
                children="More Devices"
                // style={{
                //   float: "right",
                //   backgroundColor: "#1976d2",
                //   marginRight: "12px",
                //   color: "#ffffff !important",
                //   borderRadius: "0px !important",
                // }}
                disabled={!this.props.isNextPage}
                onClick={this.nextPage}
              />*/}
            </div>

            {/* <RaisedButton
            style={{ float: "right" }}
            label="Cancel"
            onClick={this.handleCancel}
          /> */}
          </div>
        </div>
        {this.advanceSearchUI()}
      </>
    );
  }
}

export default DeviceList;
